import React, {useState} from 'react'
import './popup.scss'
import '../Form/form.scss'
import InputMask from 'react-input-mask';
import { ReactComponent as Call } from '../../img/call-white.svg'
import {useController, useForm} from "react-hook-form";
import axios from 'axios'

function Popup(props) {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit,control, watch, formState: { errors } } = useForm();
    const onSubmit = data => {
        setLoading(true)
        console.log(data)
        console.log(data.phone.length)
        const url = `https://noveltyexpress.bitrix24.ru/rest/28/46xak0rruo5lnagt/crm.lead.add.json?FIELDS[TITLE]=Лид с сайта&FIELDS[NAME]=${data.name}&FIELDS[PHONE][0][VALUE]=${data.phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '')}`
        console.log(url)
        axios.get(url).then(u=>{
            alert('Зяявка отправлена! Ожидайте звонка')
        }).finally(u=>{
            setLoading(false);
            props.setTrigger(false)
        })
    };
    const {
        field,
        fieldState: { invalid, isTouched, isDirty },
        formState: { touchedFields, dirtyFields }
    } = useController({
        name: 'phone',
        control,
        rules: { required: true, minLength: 18 },
    });
    return (props.trigger) ? (
        <div className='popup'>
            <div onClick={() => props.setTrigger(false)} className="popup__bg">
            </div>
            <div className="popup__window">
                <section className="form pop">
                <button className='pop-close' onClick={() => props.setTrigger(false)}></button>
                    <div className="container">
                        <div className="title__wrapper">
                            <h2 className="title pop">Рассчитать <br />
                                стоимость</h2>

                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}  className="form-wrapper pop">
                            <div className="searchformfld pop">
                                <input id='candidateName' className='candidateName' type="text" {...register('name', {required: true})} />
                                <label htmlFor='candidateName'>Ваше имя</label>
                            </div>
                            <div className="item2 pop">
                                <InputMask value={field.value} onChange={field.onChange} name={field.name} mask="+7 (***) ***-**-**" maskChar={null} placeholder='+7 (' />
                            </div>
                            <div className="item3 pop">
                                <button type='submit' disabled={loading}>{loading?"Отправляем...":"Отправить заявку"} <Call /> </button>
                            </div>
                        </form>
                    </div>
                </section>

                {props.children}
            </div>

        </div>

    ) : "";
}

export default Popup
