import React from 'react'
import './aboutMain.scss'
import MainPic from '../../img/about-main-cargo.png'
import { useState } from 'react';

function AboutMain() {
    const [isActive, setIsActive] = useState(false);
    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setIsActive(current => !current);
    };
    return (
        <div className='about-main'>
            <div className="container">
                <div className="about-main__wrapper">
                    <div className="left">
                        <h1 className="about-main__title">О компании</h1>
                        <p>
                            Компания Новелти Экспресс занимается полным спектром услуг по поставкам, таможенному оформлению, хранению, обработке и доставке термолабильных грузов с соблюдением "холодовой цепи".<br/><br/>

                            Собственный лицензированный склад, расположенный в непосредственной близости от аэропорта Шереметьево,  позволяет консолидировать грузы и обеспечивать доставку в регионы России в течении 24 часов.<br/><br/>

                            Более 10 лет мы осуществляем логистику для медицинского рынка с использованием самого современного и надежного оборудования.<br/><br/>

                            Собственный штат разработчиков позволяет обеспечить глубокую интеграцию с процессами клиента и предоставить высочайший сервис.
                            <button className={isActive ? 'bg-salmon' : ''} onClick={handleClick}> <b>читать ещё</b><span> <br /><br /> Каждый день благодаря нашей работе мир становится лучше: мы спасаем и улучшаем качество жизни пациентов, больше людей получает доступ к самым современным и эффективным разработкам. Мы любим то, что мы делаем!</span></button> </p>
                    </div>

                    <div className="right">
                        <img src={MainPic} alt="передача товара клиенту" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutMain
