import React,{useState} from 'react'
import './form.scss'
import InputMask from 'react-input-mask';
import { ReactComponent as Call } from '../../img/call-white.svg'
import {useController, useForm} from "react-hook-form";
import axios from "axios";

function Form (props)  {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit,control, watch, formState: { errors } } = useForm();
    const onSubmit = data => {
        setLoading(true)
        console.log(data)
        console.log(data.phone.length)
        const url = `https://noveltyexpress.bitrix24.ru/rest/28/46xak0rruo5lnagt/crm.lead.add.json?FIELDS[TITLE]=Лид с сайта&FIELDS[NAME]=${data.name}&FIELDS[PHONE][0][VALUE]=${data.phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '')}`
        console.log(url)
        axios.get(url).then(u=>{
            alert('Зяявка отправлена! Ожидайте звонка')
        }).finally(u=>{
            setLoading(false);
            props.setTrigger(false)
        })
    };
    const {
        field,
        fieldState: { invalid, isTouched, isDirty },
        formState: { touchedFields, dirtyFields }
    } = useController({
        name: 'phone',
        control,
        rules: { required: true, minLength: 18 },
    });

    return (
        <section className="form">
            <div className="container">
                <div className="title__wrapper">
                    <h2 className="title">Рассчитать <br />
                        стоимость</h2>
                    <span>Заполните форму и наш менеджер свяжется <br />
                        с Вами в ближайшее время.</span>
                </div>
                <form className="form-wrapper" onSubmit={handleSubmit(onSubmit)} >
                    <div className="searchformfld">
                        <input id='candidateName' className='candidateName'  {...register('name', {required: true})}  type="text" />
                        <label htmlFor='candidateName'>Ваше имя</label>
                    </div>
                    <div className="item2">
                        <InputMask value={field.value} onChange={field.onChange} name={field.name} mask="+7 (***) ***-**-**" maskChar={null} placeholder='+7 (' />

                    </div>
                    <div className="item3">
                        <button type='submit'disabled={loading}>{loading?"Отправляем...":"Отправить заявку"} <Call /> </button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Form
