import React from 'react'
import './quad.scss'

function Quad() {
    return (
        <section className="quad">
            <div className="container">
                <div className="title pc">Наши преимущества</div>
                <div className="title mob">Наши <br /> преимущества</div>
                <div className="quad-wrapper">
                    <div className="quad__item">
                        <h3>Оборудование</h3>
                        <p>Соответствует требованиям Приказа 646н и СП 3.3.2.3332−16, измерительные приборы внесены в Госреестр средств измерений.</p>
                    </div>
                    <div className="quad__item">
                        <h3>Профессиональный опыт</h3>
                        <p>Мы  занимаемся доставкой радиофармпрепаратов с ультра-коротким периодом полураспада (это диагностические и терапевтические лекарственные радиоактивные препараты которые теряют свою активность со временем).</p>
                    </div>
                    <div className="quad__item">
                        <h3>Надежность</h3>
                        <p>Полный контроль за происходящим через самую прогрессивную систему трекинга на рынке. Наши специалисты полностью проработают процедуру ввоза / вывоза и подготовят за вас все необходимые документы.</p>
                    </div>
                    <div className="quad__item">
                        <h3>Строгое соблюдение норм</h3>
                        <p>Соблюдение температурного режима – основное требование перевозке химических веществ, медицинских грузов и лекарственных средств. Мы заботимся о точном соблюдении всех температурных показателей заявленным требованиям. </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Quad
