import React from 'react';
import './home.scss';
import cargoman from '../../img/cargoman.jpg';
import Advantages from '../../comps/Advantages/Advantages';
import Whywe from '../../comps/Whywe/Whywe';
import Work from '../../comps/Work/Work';
import Partners from '../../comps/Partners/Partners';
import Form from '../../comps/Form/Form';
import Popup from '../../comps/Popup/Popup';
import { useState } from 'react';
import { ReactComponent as CallB } from '../../img/call-blue.svg'
import Workmob from '../../comps/Workmob/Workmob';
import { ReactComponent as Express } from '../../img/express.svg'

const Home = () => {
    const [buttonPopup, setButtonPopup] = useState(false);
    return (
        <main className="body">
            <section className="cargo">
                <div className="container">
                    <div className="cargo-wrapper">
                        <div className="cargo__text">
                          <h2>Компания Новелти Экспресс занимается полным спектром услуг по поставкам, таможенному оформлению, хранению, обработке и доставке термолабильных грузов с соблюдением "холодовой цепи".<br/><br/>

                            Собственный лицензированный склад, расположенный в непосредственной близости от аэропорта Шереметьево,  позволяет консолидировать грузы и обеспечивать доставку в регионы России в течении 24 часов.<br/><br/>

                            Более 10 лет мы осуществляем логистику для медицинского рынка с использованием самого современного и надежного оборудования.<br/><br/>

                            Собственный штат разработчиков позволяет обеспечить глубокую интеграцию с процессами клиента и предоставить высочайший сервис.</h2>
                            <button onClick={() => setButtonPopup(true)} className='call-white'>
                                Оставить заявку <CallB />
                            </button>
                        </div>
                        <div className="cargo__img">
                            <Express />
                            <img src={cargoman} alt="Сотрудник службы доставки" />
                        </div>
                    </div>
                </div>
            </section>
            <Advantages name={`Что мы\nпредлагаем?`} />
            <Whywe />
            <Work  />
            <Workmob  />
            <Partners />
            <Form />
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>

            </Popup>
        </main>



    )
}

export default Home;
